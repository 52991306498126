<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="review-order-start rating-main  box-flex">
      <div class="review-container review-main text-capitalize position-relative  box-flex__wrapper">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="rate-box">
              <div class="resturant-details d-flex align-items-start plr15">
                <div class="resturant-details-box">
                  <div class="imgcls pr-3">
                    <img :src="restaurantData.restaurant_image" />
                  </div>
                  <div class="status-con">
                    <h6>{{ restaurantData.restaurant_name }}</h6>
                    <h4>{{$t('Rate your food Experience')}}</h4>
                  </div>
                </div>
              </div>
              <div class="rate-status-main align-items-center justify-content-between mb-3">
                <div class="rate-stars">
                  <span>1<i class="fas active fa-star me-2" :class="{ active: orderRating >= 1 }" @click="addOrderStar(1)"></i></span>
                  <span>2<i class="fas fa-star me-2" :class="{ active: orderRating >= 2 }" @click="addOrderStar(2)"></i></span>
                  <span>3<i class="fas  fa-star me-2" :class="{ active: orderRating >= 3 }" @click="addOrderStar(3)" ></i></span>
                  <span>4<i class="fas fa-star me-2" :class="{ active: orderRating >= 4 }" @click="addOrderStar(4)"></i></span>
                  <span>5<i class="fas fa-star me-2" :class="{ active: orderRating >= 5 }" @click="addOrderStar(5)"></i></span>
                </div>

                <p class="rate-status" v-if="orderRating == 1">{{$t('Poor')}} 😡</p>
                <p class="rate-status" v-else-if="orderRating == 2">{{$t('Fair')}} 😐</p>
                <p class="rate-status" v-else-if="orderRating == 3">{{$t('Good')}} 🙂</p>
                <p class="rate-status" v-else-if="orderRating == 4">{{$t('Very Good')}} 😊</p>
                <p class="rate-status" v-else-if="orderRating == 5">{{$t('Excellent')}} 😄</p>
              </div>
              <input class="form-control mb-0 h50" type="text" v-model="oderrateingtext" :placeholder="$t('Write review for',{msg:restaurantData.restaurant_name})" aria-label="Write Your Review"/>
            </div>
            <div class="rate-box" v-if="restaurantData.delivery_pickup_types == 'Delivery' && restaurantData.driverid != '' && restaurantData.driverid > 0">
              <div class="resturant-details d-flex align-items-start plr15">
                <div class="resturant-details-box">
                  <div class="imgcls pr-3">
                    <!-- <img :src="restaurantData.driver_image" class="mr-3" /> -->
                    <img :src="restaurantData.driver_image" @error="$event.target.src = noImge" />
                  </div>
                  <div class="status-con">
                    <h6 v-if="restaurantData.drivername">{{ restaurantData.drivername }}</h6>
                    <h4>{{$t('Rate our delivery service')}}</h4>
                  </div>
                </div>
              </div>              
              <div class="rate-status-main align-items-center justify-content-between mb-3">
                <div class="rate-stars">
                  <span>1<i class="fas fa-star me-2" :class="{ active: driveRating >= 1 }" @click="addDriveStar(1)"></i></span>
                  <span>2<i class="fas fa-star me-2" :class="{ active: driveRating >= 2 }" @click="addDriveStar(2)"></i></span>
                  <span>3<i class="fas fa-star me-2" :class="{ active: driveRating >= 3 }" @click="addDriveStar(3)"></i></span>
                  <span>4<i class="fas fa-star me-2" :class="{ active: driveRating >= 4 }" @click="addDriveStar(4)"></i></span>
                  <span>5<i class="fas fa-star me-2" :class="{ active: driveRating >= 5 }" @click="addDriveStar(5)"></i></span>
                </div>
                <p class="rate-status" v-if="driveRating == 1">{{$t('Poor')}} 😡</p>
                <p class="rate-status" v-else-if="driveRating == 2">{{$t('Fair')}} 😐</p>
                <p class="rate-status" v-else-if="driveRating == 3">{{$t('Good')}} 🙂</p>
                <p class="rate-status" v-else-if="driveRating == 4">{{$t('Very Good')}} 😊</p>
                <p class="rate-status" v-else-if="driveRating == 5">{{$t('Excellent')}} 😄</p>
              </div>
              <input class="form-control mb-0 h50" type="text" v-model="driverratingtext" :placeholder="$t('Write review for',{msg:restaurantData.drivername})" aria-label="Write Your Review"/>
            </div>
            <div class="deliveryaddress border-0 rate-btn bottom-sticky">
              <div class="full-btn">
                <a href="javascript:void(0)" @click="submitRating()">{{$t('Submit')}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>  
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import { mapActions } from "vuex";
import noImge from "@/assets/images/place_holder.png";
import Footer from '../components/Footer.vue';

export default {
  components: { NavBar,Footer },
  data() {
    return {
      orderRating: 3,
      driveRating: 3,
      oderrateingtext: "",
      driverratingtext: "",
      restaurantName :'',
      restaurantData : [],
      ratingExiting: false,
      noImge
    };
  },
  
  mounted(){
    if(this.$auth.user){
      this.checkRating();      
    }else{
      this.$router.push({ name: "sign-in" });
    }
  },
  methods: {
    ...mapActions("order", ["orderRatingData","viewOrderDetail","checkOrderRating"]),

    addOrderStar(rate) {
      this.orderRating = rate;
    },

    addDriveStar(rate) {
      this.driveRating = rate;
    },

    submitRating() {
      this.orderRatingData({
        vendor_id: this.$auth.getVendorId(),
        order_id: this.base64_decode(this.$route.params.order_id),
        rating: this.orderRating,
        description: this.oderrateingtext,
        rating_driver: this.driveRating,
        description_driver: this.driverratingtext,
        is_langauge: this.$store.state.lang.locale,
      }).then((data) => {
        if (data.code == 200) {
          this.$router.push({name: "order-status",params: this.$route.params.order_id});
        }
      });
    },
  
    orderDetails(){
      this.viewOrderDetail({
          vendor_id: this.$auth.getVendorId(),
          order_id: this.base64_decode(this.$route.params.order_id),
          is_langauge : this.$store.state.lang.locale
      }).then(data => {
        if (data.code == 200) {
          this.restaurantName = data.Result[0].restaurant_name;
          this.restaurantData = data.Result[0];
        }
      })
    },

    checkRating() {
      this.checkOrderRating({
        vendor_id: this.$auth.getVendorId(),
        order_id: this.base64_decode(this.$route.params.order_id),
        is_langauge: this.$store.state.lang.locale,
      }).then((data) => {
        if (data.code == 200) {
          this.ratingExiting = data.Result.status;
          if(data.Result.status === 'false') {
            this.orderDetails();
          }else{
            this.$router.go(-1);            
          }
        }
      });
    },

  },
};
</script>
